import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindMenu } from "material-ui-popup-state";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import useNotification from "../../../hooks/notification";
import useMutation from "../../../hooks/useMutation";
import {
  DELETE_INVENTORY_ITEM_MUTATION,
  UPDATE_INVENTORY_ITEM_MUTATION,
} from "../../../constants/graphql/mutations";
import { INVENTORY_REQUEST_QUERY } from "../../../constants/graphql/queries";
import { getInventoryFields } from "src/views/inventory/requests/CartItemFields";

// Priority order for status colors
const statusOrder = [
  { key: "canceled", label: "Cancelled" },
  { key: "completed", label: "Completed" },
  { key: "picked", label: "Picked" },
  { key: "scheduled", label: "Scheduled" },
  { key: "pending_approval", label: "Pending Approval" },
  { key: "submitted", label: "Submitted" },
];

// Order for dropdown menu
const dropdownStatusOrder = [
  { key: "pending_approval", label: "Pending Approval" },
  { key: "scheduled", label: "Scheduled" },
  { key: "picked", label: "Picked" },
  { key: "completed", label: "Completed" },
  { key: "canceled", label: "Cancelled" },
];

const statusColors = {
  submitted: "#E0E0E0",
  pending_approval: "#FFECB3",
  scheduled: "#FFE5CC",
  picked: "#CCE7FF",
  completed: "#D5F5D5",
  canceled: "#F8D7DA",
};

const styles = {
  menuPaper: {
    backgroundColor: "white",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    maxHeight: 300,
    overflowY: "auto",
  },
  checkIcon: {
    mr: 1,
  },
  statusBox: selectedStatus => ({
    cursor: "pointer",
    padding: "8px 12px",
    border: `1px solid ${statusColors[selectedStatus || "submitted"]}`,
    borderRadius: 1,
    backgroundColor: statusColors[selectedStatus || "submitted"],
    color: "black",
    textAlign: "center",
    fontWeight: 500,
  }),
};

export default function VaStatus({ disabled, inventoryRequest, changeStatus, reFetch }) {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const { notifySuccess } = useNotification();
  const [deleteInventoryItem] = useMutation(DELETE_INVENTORY_ITEM_MUTATION);
  const { refetch } = useQuery(INVENTORY_REQUEST_QUERY, {
    variables: { id: inventoryRequest.id },
    skip: true,
  });
  const [updateItem] = useMutation(UPDATE_INVENTORY_ITEM_MUTATION);

  useEffect(() => {
    const activeStatus = statusOrder.find(
      status => inventoryRequest?.vaStatus[status.key] === true
    );
    if (activeStatus) setSelectedStatus(activeStatus.key);
  }, [inventoryRequest]);

  const handleCancel = async (status, checked) => {
    setLoading(true);
    try {
      const { data } = await refetch();
      const { inventoryItems } = data.inventoryRequest;

      await Promise.all(
        inventoryItems.map(item => deleteInventoryItem({ variables: { id: item.id } }))
      );

      await changeStatus(status)(checked);
      await reFetch();

      notifySuccess("Order successfully cancelled");

      setSelectedStatus(status);
    } catch (error) {
      console.error("Error during cancellation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkAsPicked = async () => {
    const { data } = await refetch();
    const { inventoryItems } = data.inventoryRequest;

    return Promise.all(
      inventoryItems
        .filter(item => !item.pickedStatus)
        .map(item =>
          updateItem({
            variables: {
              id: item.id,
              ...getInventoryFields({
                ...item,
                pickedStatus: true,
              }),
            },
          })
        )
    );
  };

  const handleChangeStatus = status => async value => {
    const checked = value.target.checked;
    if (status === "canceled" && checked && reFetch) {
      await handleCancel(status, checked);
    } else if (["picked", "completed"].includes(status) && checked) {
      await handleMarkAsPicked();
      await changeStatus(status)(checked);
      setSelectedStatus(status);
      if (reFetch) await reFetch();
    } else {
      await changeStatus(status)(checked);
      setSelectedStatus(status);
      if (reFetch) await reFetch();
    }
  };

  return (
    <PopupState variant="popover" popupId="status-menu">
      {popupState => (
        <ClickAwayListener onClickAway={popupState.close}>
          <Box maxWidth={300} display="flex" flexDirection="column">
            <FormControl fullWidth>
              <Box onClick={popupState.open} sx={styles.statusBox(selectedStatus)}>
                {statusOrder.find(status => status.key === selectedStatus)?.label ||
                  "Select Status"}
              </Box>
            </FormControl>

            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              PaperProps={{
                sx: styles.menuPaper,
              }}
            >
              {dropdownStatusOrder.map(({ key, label }) => (
                <MenuItem key={key} disabled={disabled} onClick={popupState.close}>
                  {inventoryRequest?.vaStatus[key] ? (
                    <CheckIcon sx={styles.checkIcon} />
                  ) : (
                    <Checkbox
                      checked={inventoryRequest?.vaStatus[key] || false}
                      onChange={handleChangeStatus(key)}
                      disabled={disabled}
                    />
                  )}
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Menu>

            {loading && (
              <Box mt={2} display="flex" justifyContent="center">
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </PopupState>
  );
}
