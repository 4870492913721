export function getShipmentValues(shipment) {
  return {
    bol: shipment.bol,
    carrierName: shipment.carrierName,
    notes: shipment.notes,
    orderNumber: shipment.orderNumber,
    totalPieces: shipment.totalPieces,
    customerPoNumber: shipment.customerPoNumber,
    weightUnits: shipment.weightUnits,
    totalWeight: shipment.totalWeight,
    vendorName: shipment.vendorName,
    trackingNumber: shipment.trackingNumber,
    startTime: shipment.startTime,
    completeTime: shipment.completeTime,
  };
}
